<template>
  <div class="advertisers-questions">
    <div class="container">
      <h2 class="title">{{ $t("advertPeopleTitle") }}</h2>
      <div class="advertisers-questions__items">
        <div
          class="advertisers-questions__item"
          v-for="(advert, index) in $t('advertPeoples')"
          :key="index"
        >
          <div class="advertisers-questions__name">{{ advert.name }}</div>
          <div class="advertisers-questions__position">
            {{ advert.position }}
          </div>
          <a
            href="mailto:nurpeissova.z@magnum.kz"
            class="advertisers-questions__email"
            >{{ advert.email }}</a
          >
          <a href="tel:+77273391350" class="advertisers-questions__phone">{{
            advert.phone
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertisersQuestions",
};
</script>